/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'

// Images
import Pin from 'img/pin.inline.svg'
import Phone from 'img/phone.inline.svg'
import Mail from 'img/mail.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import ParseContent from 'components/shared/ParseContent'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  color: ${(props) => props.theme.color.light};
  padding: 50px 0 0 0;

  & a {
    color: ${({ theme }) => theme.color.light};
  }

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 20px;
    line-height: 24px;
    padding: 0 0 15px 15px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.color.primary};
      height: 24px;
      width: 2px;
      left: 0px;
      top: 0;
    }
  }

  & ul {
    padding-left: 15px;

    & li {
      font-weight: ${({ theme }) => theme.font.weight.light};
      list-style: none;
      position: relative;
      font-size: 16px;
      line-height: 30px;

      &:before {
        content: '';
        position: absolute;
        height: 4px;
        width: 4px;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.color.light};
        left: -15px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }

      & a {
        color: ${({ theme }) => theme.color.light} !important;
      }

      &:hover {
        a {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 15px 0 0 0;

    & h2 {
      padding-bottom: 5px;
      margin-top: 10px;
    }
  }
`

const Content = styled(ParseContent)`
  & p {
    color: ${({ theme }) => theme.color.light};
    font-size: 16px;
    line-height: 30px;
  }

  & a {
    color: ${({ theme }) => theme.color.primary} !important;
  }
`

const SvgWrapper = styled.div`
  width: 15px;
  display: flex;
  justify-content: flex-end;

  & svg {
    position: absolute;
    width: 20px;
    height: 22px;
  }

  @media screen and (max-width: 575px) {
    width: 25px;
  }
`

const CustomLink = styled.div<{ linkable: boolean }>`
  position: relative;
  padding: 0 15px;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.font.weight.light};

  ${({ linkable }) =>
    linkable &&
    css`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `}

  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: ${({ theme }) => theme.color.primary};
    left: 0px;
  }

  &:first-of-type {
    &:before {
      content: none;
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuTitle
          menuFooter {
            link {
              title
              url
              target
            }
          }
          applicationsTitle
          applicationsFooter {
            link {
              title
              url
              target
            }
          }
          techTitle
          techFooter {
            link {
              title
              url
              target
            }
          }
          contactTitle
          address
          phone {
            title
            url
          }
          mail {
            title
            url
          }
          links {
            link {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container py-lg-5 py-3">
        <div className="row">
          <div className="col-lg-3">
            <h2>{fields?.footer?.menuTitle}</h2>
            <ul>
              {fields?.footer?.menuFooter?.map(
                ({ link: { url, title, target } }: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url} target={target}>{parse(title)}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-lg-3">
            <h2>{fields?.footer?.applicationsTitle}</h2>
            <ul>
              {fields?.footer?.applicationsFooter?.map(
                ({ link: { url, title } }: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url}>{parse(title)}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-lg-3" />
          {/* <div className="col-lg-3">
            <h2>{fields?.footer?.techTitle}</h2>
            <ul>
              {fields?.footer?.techFooter?.map(
                ({ link: { url, title } }: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url}>{parse(title)}</Link>
                  </li>
                )
              )}
            </ul>
          </div> */}
          <div className="col-lg-3">
            <h2>{fields?.footer?.contactTitle}</h2>
            <div>
              <div className="d-flex pb-lg-4 pb-3">
                <SvgWrapper className="pe-2">
                  <Pin />
                </SvgWrapper>
                <Content content={fields?.footer?.address || ''} />
              </div>
              {/* <div className="d-flex pb-2">
                <SvgWrapper className="pe-2">
                  <Phone />
                </SvgWrapper>
                <a target="__blank" href={fields?.footer?.phone?.url || '/'}>
                  {fields?.footer?.phone?.title}
                </a>
              </div>
              <div className="d-flex">
                <SvgWrapper className="pe-2">
                  <Mail />
                </SvgWrapper>
                <a target="__blank" href={fields?.footer?.mail?.url || '/'}>
                  {fields?.footer?.mail?.title}
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="pt-lg-5 mt-4 mb-lg-0 mb-3 d-flex justify-content-center">
          {fields?.footer?.links?.map((l, index) => (
            <CustomLink linkable={l?.link?.url !== '/'} key={index}>
              <div
                onClick={
                  l?.link?.url === '/'
                    ? () => { }
                    : () => navigate(l?.link?.url || '/')
                }
              >
                {l?.link?.title}
              </div>
            </CustomLink>
          ))}
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
