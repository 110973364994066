import React from 'react'
import { navigate } from 'gatsby'

// Images
import Search from 'img/search.inline.svg'
import Support from 'img/support.inline.svg'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    padding-left: 0;
    margin: 0;
    width: 70%;
    margin-top: -150px;
  }

  @media screen and (max-width: 575px) {
    & ul {
      width: 80%;
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.font.weight.light};
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 60px;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 350px) {
    margin-right: 30px;
  }
`

const SupportContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 12px 30px;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.primaryDark};
  }
`

const ExtraContainer = styled.div`
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: space-evenly;
`

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
          <ExtraContainer>
            <SearchContainer
              onClick={() => navigate(fields.header?.search?.url || '/')}
            >
              <div className="pe-2">
                <Search />
              </div>
              {fields.header?.search?.title}
            </SearchContainer>
            <SupportContainer
              onClick={() => navigate(fields.header?.support?.url || '/')}
            >
              <div className="pe-2">
                <Support />
              </div>
              {fields.header?.support?.title}
            </SupportContainer>
          </ExtraContainer>
        </MenuWrapper>
      )}
    </div>
  )
}

export default Mobile
