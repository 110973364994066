/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import Search from 'img/search.inline.svg'
// import Support from 'img/support.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled from 'styled-components'
import useLayout from 'hooks/useLayout'
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonOutline from 'components/elements/Buttons/BannerOutline'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: ${(props) => props.theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 95px;
  z-index: 10;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); */
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.25));
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  & > svg {
    height: auto;
    width: 135px;
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  location?: any
}

const SearchContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.font.weight.light};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

// const SupportContainer = styled.div`
//   position: absolute;
//   bottom: -50px;
//   right: 0;
//   height: 50px;
//   display: flex;
//   align-items: center;
//   padding: 12px 30px;
//   background-color: ${({ theme }) => theme.color.primary};
//   color: ${({ theme }) => theme.color.light};
//   font-weight: ${({ theme }) => theme.font.weight.bold};
//   transition: all 0.3s ease;

//   &:hover {
//     cursor: pointer;
//     background-color: ${({ theme }) => theme.color.primaryDark};
//   }

//   @media screen and (max-width: 991px) {
//     display: none;
//   }
// `

const StyledTrackingBar = styled.div`
  background-color: ${({ theme }) => theme.color.primary};

  & h4 {
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 26px;
    line-height: 30px;
    text-align: center;

    @media (max-width: 991px){
      font-size: 18px;
    }

    @media (max-width: 575px){
      margin-bottom: 1rem !important;
    }
  }
`

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
  location
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            submenu
            link {
              title
              url
              target
            }
            title
            menu {
              link {
                title
                url
                target
              }
            }
          }
          search {
            title
            url
          }
          support {
            title
            url
          }
          trackingbar {
            description
            link {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  const [isHome, setIsHome] = React.useState<boolean>(false)

  useEffect(() => {

    if (location && location.pathname === '/' || location && location.pathname === '/axign-dev.nl/' || location && location.pathname === '/axigndev.nl/') {
      setIsHome(true)
    } else {
      setIsHome(false)
    }

  }, [location])

  return (
    <>
      <StyledHeader isSticky={isSticky}>
        <Container className="container">
          <Brand to="/">
            <Logo />
          </Brand>

          <Mobile
            fields={fields}
            setIsScrollable={setIsScrollable}
            isSticky={isSticky}
          />
          <Desktop fields={fields} isSticky={isSticky} />
          <SearchContainer
            onClick={() => navigate(fields.header.search.url || '/')}
          >
            <div className="pe-2">
              <Search />
            </div>
            {fields.header.search.title}
          </SearchContainer>
        </Container>
        {/* <SupportContainer
        onClick={() => navigate(fields.header.support.url || '/')}
      >
        {fields.header.support.title}
      </SupportContainer> */}
        {!isHome && (
          <StyledTrackingBar>
            <div className="d-flex flex-wrap align-items-center justify-content-center py-3">
              <ParseContent content={fields.header.trackingbar.description} />
              <ButtonOutline to={fields.header.trackingbar.link.url} className="ms-sm-4" target="_blank">{fields.header.trackingbar.link.title}</ButtonOutline>
            </div>
          </StyledTrackingBar>
        )}
      </StyledHeader>
    </>
  )
}

export default Header
