import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

// Theme
import Theme from 'styles/Global'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'
import FormModal from 'components/elements/FormModal'

// CSS
import 'styles/all.css'
import 'bootstrap/dist/css/bootstrap.css'

export interface LayoutContextProps {
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
  setEventModal: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  translations: [],
  setIsScrollable: (bool: any) => bool,
  setEventModal: (bool: boolean) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  padding-top: 95px;

  &.extra-padding {
    padding-top: 187px;
  }
`

interface LayoutProps {
  children: any
  locale?: string
  translations?: any
  location?: any
}

const Layout: React.FC<LayoutProps> = ({
  location,
  children,
  locale = 'nl_NL',
  translations,
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const [isHome, setIsHome] = useState<boolean>(false)

  const [eventModal, setEventModal] = useState<boolean>(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {

    if (location && location.hash === '#schedule') {
      navigate('#blank')
      setEventModal(true)
    }

    if (location && location.pathname === '/' || location && location.pathname === '/axign-dev.nl/' || location && location.pathname === '/axigndev.nl/') {
      setIsHome(true)
    } else {
      setIsHome(false)
    }

  }, [location])

  console.log(location)
  console.log(isHome, 'isHome');

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider
        // @ts-ignore
        value={{ setEventModal, setIsScrollable, locale, translations }}
      >
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} location={location} />
        <Content isSticky={isSticky} className={`${!isHome ? 'extra-padding' : ''}`}>{children}</Content>
        {eventModal && <FormModal setEventModal={setEventModal} />}
        <Footer />
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
