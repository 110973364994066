import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonPrimaryCSS = css`
  background-color: ${(props) => props.theme.color.primary};
  border: 2px solid ${({ theme }) => theme.color.primary};
  display: inline-block;
  transition: all 0.3s ease;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    padding: 8px 40px;
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    line-height: 40px;
    display: block;
    text-decoration: none !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.primaryDark};
    border-color: ${({ theme }) => theme.color.primaryDark};
  }

  @media screen and (max-width: 575px) {
    & > a,
    & > button {
      padding: 10px 16px;
      font-size: 16px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.light};
    }
  }
`

const StyledButton = styled.span`
  ${ButtonPrimaryCSS};
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to?: string
  className?: string
  target?: string
  onClick?: () => void
}


const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
  onClick = () => null,
}) => (
  <StyledButton onClick={onClick} className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimary
