/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Services
import convertStyle from 'services/convertStyle'

// Images
import axigndot from 'img/axign-dot.png'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonPrimaryOutlined from 'components/elements/Buttons/ButtonPrimaryOutlined'
import useLayout from 'hooks/useLayout'

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.light};
  font-size: 16px;
  color: ${({ theme }) => theme.color.dark};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  & h1 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};
    padding-bottom: 15px;

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 60px;
      line-height: 60px;
    }

    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 40px;
    }

    @media (max-width: 575px) {
      font-size: 30px;
      line-height: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h2 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 60px;
      line-height: 60px;
    }

    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h3 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};
    margin-bottom: 0;

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 40px;
    }

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h4 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};
    margin-bottom: 0;

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 30px;
      line-height: 60px;
    }

    @media (max-width: 1199px) {
      font-size: 20px;
      line-height: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    & li {
    }

    @media (max-width: 575px) {
      & li {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  & .axign-dots {
    & li {
      position: relative;
      list-style: none;
      font-size: 20px;
      line-height: 40px;

      &:before {
        content: '';
        position: absolute;
        left: -30px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        background-image: url(${axigndot});
        background-size: contain;
        background-repeat: no-repeat;
        height: 20px;
        width: 20px;
      }
    }

    @media (max-width: 575px) {
      & li {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  & p {
    font-size: 20px;
    line-height: 40px;
    font-weight: ${({ theme }) => theme.font.weight.light};

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 30px;
    }

    @media screen and (max-width: 350px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: underline;
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }
`

const findAndReplace = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      const layout = useLayout()

      const { name, attribs, children }: any = domNode
     
      if (name === 'a' && attribs.class === 'button-primary') {   
        
             
        if (attribs.href.includes('#schedule')) {
          console.log(domNode, '123');
          return (
            <button
              className={attribs.class}
              onClick={() => layout.setEventModal(true)}
              type="button"
            >
              {domToReact(children, {})}
            </button>
          )
        }
        
        return (
          <ButtonPrimary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonPrimary>
        )
      }

      if (name === 'a' && attribs.class === 'button-primary-outlined') {

        return (
          <ButtonPrimaryOutlined
            to={attribs.href}
            target={attribs.target || ''}
          >
            {domToReact(children, {})}
          </ButtonPrimaryOutlined>
        )
      }

      if (name === 'a') {
        const attributes = attribs

        if (attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1 ||
          attributes.href.indexOf('tel:') !== -1 ||
          attributes.href.indexOf('mailto:') !== -1
        ) {
          return <a {...attributes}>{domToReact(children, {})}</a>
        }

        return (
          <Link to={attributes.href} {...attributes}>
            {domToReact(children, {})}
          </Link>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return <Content className={className}>{findAndReplace(content)}</Content>
}

export default ParseContent
