/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_menu>[]
}

const Item = styled.li`
  list-style: none;
  line-height: 30px;
  font-size: 16px;
  padding-left: 15px;
  position: relative;

  & > a {
    color: ${(props) => props.theme.color.dark};
    transition: all 0.3s ease;

    &[aria-current] {
    }

    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  &:hover {
    &:before {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }

  &:before {
    content: '';
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.color.dark};
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 991px) {
    & > a {
      font-size: 24px;
      line-height: 30px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }

    &:before {
      content: none;
    }
  }

  @media screen and (max-width: 575px) {
    & > a {
      font-size: 24px;
      line-height: 24px;
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;
  min-width: 320px;

  @media screen and (min-width: 992px) {
    position: absolute;
    overflow: hidden;
    padding-top: 30px;
    left: -20px;

    & > div {
      background-color: ${({ theme }) => theme.color.light};
      padding: 30px 50px 30px 30px;
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    padding-top: unset;
    width: 100%;

    & > div {
      padding: 10px 5px 2px 20px;
    }
  }

  @media screen and (max-width: 575px) {
    & > div {
      padding: 10px 5px 0px 10px;
    }
  }
`

const Submenu: React.FC<SubmenuProps> = ({ fields }) => (
  <StyledSubmenu
    initial={{ height: 0 }}
    animate={{ height: 'min-content' }}
    exit={{ height: 0 }}
  >
    <div>
      {fields.map((f, index) => (
        <Item>
          <NavLink to={f?.link?.url || '/'} key={index}>
            {f?.link?.title}
          </NavLink>
        </Item>
      ))}
    </div>
  </StyledSubmenu>
)

export default Submenu
