import React, { useEffect } from 'react'
import styled from 'styled-components'

// Third party
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import Modal from 'react-modal'
import useLayout from 'hooks/useLayout'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import { motion, AnimatePresence } from 'framer-motion'

// Images
import Cancel from 'img/cancel.inline.svg'
import { useMedia } from 'react-use'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1rem;
    position: relative;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #dadada;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding: 0 10px;
  }

  & .form-duuf-label {
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding-bottom: 5px;
  }

  & .form-duuf-error {
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 0;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-checkbox-consent {
    text-decoration: underline;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const SvgWrapper = styled.div`
  right: 25px;

  & svg {
    width: 25px;
    height: 25px;

    & path {
      fill: ${({ theme }) => theme.color.dark};
    }
  }
`

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.color.light};
`
interface FormModalProps {
  setEventModal: Function
}

const FormModal: React.FC<FormModalProps> = ({ setEventModal }) => {
  const [isOpen, setIsOpen] = React.useState(true)

  const mobile = useMedia('(max-width: 575px)')

  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      height: mobile ? '90%' : 'auto',
      width: mobile ? '100%' : '50%',
      overflowY: mobile ? 'scroll' : 'none',
      zIndex: 999,
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '24px',
      padding: '3rem',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      background: 'rgba(9, 21, 79, 0.25)',
    },
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={() => setEventModal(false)}
      // @ts-ignore
      style={customStyles}
    >
      <SvgWrapper className="d-flex justify-content-end mb-4">
          <Cancel onClick={() => setEventModal(false)} role="button" />
        </SvgWrapper>
      <StyledFormDuuf
         privacyUrl={'/privacy-policy/'}
         privacyUrlPlacement="privacy policy"
         id={3}
         className="mt-lg-5 mt-2"
         generate={false}
      >
        <div className="row">
          <div className="col-md-6">
            <FormDuufGroup showIds={[4,9]} />
          </div>
          <div className="col-md-6">
            <FormDuufGroup showIds={[8,11]} />
          </div>
          <div className="col-md-12">
            <FormDuufGroup showIds={[13]} />
          </div>
          <div className="col-md-12">
            <FormDuufGroup showIds={[12]} />
          </div>
          <div className="col-12">
            <FormDuufSubmit />
          </div>
        </div>
      </StyledFormDuuf>
    </StyledModal>
  )
}

export default FormModal
