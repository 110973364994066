import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import mainTheme from 'src/styles/Theme'

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: ${(props) => (props.isScrollable ? 'initial' : 'hidden')};
  }

  body {
    font-family: ${(props) => props.theme.font.family.primary} !important;
    font-size: ${(props) => props.theme.font.size.medium};
    color: ${(props) => props.theme.color.primary};
  }

  .button-primary{
    background-color: ${(props) => props.theme.color.primary};
    border: 2px solid ${({ theme }) => theme.color.primary};
    display: inline-block;
    transition: all 0.3s ease;
    color: ${(props) => props.theme.color.light} !important;
    padding: 8px 40px;
    width: 300px;
    height: 100%;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    line-height: 40px;
    display: block;
    text-decoration: none !important;

    &:hover {
      background-color: ${({ theme }) => theme.color.primaryDark};
      border-color: ${({ theme }) => theme.color.primaryDark};
    }

    @media screen and (max-width: 575px) {
      padding: 10px 16px;
      font-size: 16px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.light};
    }
  }

  ${(props) =>
    Object.keys(props.theme.color).map(
      (key) =>
        `.bg-${key} { background-color: ${props.theme.color[key]}; }
        .text-${key} { color: ${props.theme.color[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.family).map(
      (key) =>
        `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.size).map(
      (key) => `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.weight).map(
      (key) =>
        `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
    )}

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
`

const Theme = ({ children, isScrollable = true }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle isScrollable={isScrollable} />
    {children}
  </ThemeProvider>
)

export default Theme
