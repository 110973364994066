const mainTheme = {
  color: {
    primary: '#1f55ff',
    primaryDark: '#1c4eff',
    secondary: '#333333',
    grey: '#F8F8F8',
    dark: '#333333',
    light: '#FFFFFF',
  },

  gradient: {
    primaryLinear:
      'linear-gradient(90deg, rgba(31,85,255,1) 20%, rgba(0,208,255,1) 100%);',
    primaryLinearSwitched:
      'linear-gradient(90deg, rgba(31,85,255,1) 20%, rgba(0,208,255,1) 100%);',
  },

  font: {
    family: {
      primary: 'Poppins, sans-serif',
      secondary: 'AXIS, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      extraBold: 900,
    },
  },
}

export default mainTheme
