/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import Chevron from 'img/chevron.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import parse from 'html-react-parser'

const Item = styled(motion.li)`
  width: 100px;
  line-height: 16px;
  position: relative;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & > a,
  & > div > a {
    color: ${(props) => props.theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    &:hover {
      /* font-weight: ${({ theme }) => theme.font.weight.bold}; */
      color: ${({ theme }) => theme.color.primary};
    }

    &:before {
      content: '';
      position: absolute;
      height: 32px;
      width: 2px;
      background-color: ${({ theme }) => theme.color.primaryDark};
      left: -10px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:hover {
      & > div {
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  @media screen and (max-width: 1199px) {
    width: 100px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    list-style: none;
    margin: 20px 0;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > a,
    & > div > a {
      font-size: 40px;
      line-height: 50px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};

      &:before {
        left: -15px;
        width: 5px;
        height: 80%;
      }
    }
  }

  @media screen and (max-width: 350px) {
    & > a {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const Title = styled.div`
  color: ${(props) => props.theme.color.dark};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  position: relative;
  font-size: 16px;
  line-height: 16px;
  cursor: default;
  transition: all 0.3s ease;

  &:hover {
    /* font-weight: ${({ theme }) => theme.font.weight.bold}; */
    color: ${({ theme }) => theme.color.primary};
  }

  &:before {
    content: '';
    position: absolute;
    height: 32px;
    width: 2px;
    background-color: ${({ theme }) => theme.color.primaryDark};
    left: -10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  @media screen and (max-width: 991px) {
    font-size: 40px;
    line-height: 50px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.light};

    &:before {
      left: -15px;
      width: 5px;
      height: 80%;
    }

    &:hover {
      &:before {
        left: -30px;
        width: 20px;
        height: 5px;
      }
    }
  }

  @media screen and (max-width: 350px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const SvgWrapper = styled(motion.div) <{ open: boolean }>`
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
  transition: all 0.3s ease;

  & svg {
    stroke: ${({ theme }) => theme.color.light};
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <Item
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          //onTap={open === index ? () => setOpen(-1) : () => setOpen(index)}
          key={index}
        >
          {l?.submenu === 'yes' ? (
            <>
              <div>
                <NavLink to={l?.link?.url || '/'}>
                  {parse(l?.link?.title || '')}
                </NavLink>
                <SvgWrapper open={open === index} onTap={open === index ? () => setOpen(-1) : () => setOpen(index)}>
                  <Chevron />
                </SvgWrapper>
              </div>
              <AnimatePresence>
                {open === index && <Submenu fields={l.menu || []} />}
              </AnimatePresence>
            </>
          ) : (
            <NavLink to={l?.link?.url || '/'} target={l?.link?.target || '_self'}>
              {parse(l?.link?.title || '')}
            </NavLink>
          )}
        </Item>
      ))}
    </>
  )
}

export default Menu
